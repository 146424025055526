import type { SerializeFrom } from "@remix-run/node";
import { useRouteLoaderData } from "@remix-run/react";

import type { loader as RootLoader } from "~/root.js";
import type { loader as AdminCompanyLoader } from "~/routes/admin.accounts.$companyId.js";
import type { loader as AdminLabelLoader } from "~/routes/admin.labels.$labelId.js";
import type { loader as PromptLayoutLoader } from "~/routes/admin.llm_.$promptId.js";
import type { loader as InstallationRootLoader } from "~/routes/s.$company.config.connections.$installationId.js";
import type { loader as LabelDetailLoader } from "~/routes/s.$company.config.labels.$labelId.js";
import type { loader as EmployeePmsLoader } from "~/routes/s.$company.employees.$employeeId/route.js";
import type { loader as WorkspaceLoader } from "~/routes/s.$company.js";

interface Loaders {
  "routes/s.$company": typeof WorkspaceLoader;
  "routes/s.$company.config.connections.$installationId": typeof InstallationRootLoader;
  "routes/s.$company.config.labels.$labelId": typeof LabelDetailLoader;
  "routes/admin.llm_.$promptId": typeof PromptLayoutLoader;
  "routes/admin.accounts.$companyId": typeof AdminCompanyLoader;
  "routes/admin.labels.$labelId": typeof AdminLabelLoader;
  "routes/s.$company.employees.$employeeId": typeof EmployeePmsLoader;
  root: typeof RootLoader;
}

export function useTypedRouteLoaderData<T extends keyof Loaders>(route: T) {
  return useRouteLoaderData(route) as SerializeFrom<Loaders[T]>;
}

export function useOptionalTypedRouteLoaderData<T extends keyof Loaders>(route: T) {
  return useRouteLoaderData(route) as SerializeFrom<Loaders[T]> | undefined;
}
